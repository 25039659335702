import React, { useState, Component } from "react";
import styled from "styled-components";
import { Row, Col, Form, Input, Button, Modal, Select } from "antd";
import { Link } from "react-router-dom";
import { data } from "jquery";
//import { Modal as SuccessModal } from "react-responsive-modal";
//import "react-responsive-modal/styles.css";

const { Option } = Select;
const { TextArea } = Input;
const selectBefore = (
  <Form.Item
    name="title"
    rules={[{ required: false, message: false }]}
    style={{ marginBottom: "0px" }}
  >
    <Select placeholder="Title" className="select-before">
      <Option value="Mr">Mr</Option>
      <Option value="Miss">Miss</Option>
    </Select>
  </Form.Item>
);
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const StyledInputTestDrive = styled(Input)`

//padding-left: 1.2em !important;
height: 45px !important;

  box-shadow:none !important;
background-color: transparent!important;
background-image: none;
border: none;
border: 0px solid black !important;
border: 1px solid #06141f !important;
border-radius: 0px;
color: #06141f!important;
//box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
.ant-input-group-addon .ant-select {
  margin: 0em !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
padding-right: 20px !important;
color: #06141f !important;
}
.ant-select-arrow {

color: #06141f !important;

}
.ant-input-group-addon {
padding: 0px !important;
color: #06141f !important;
background-color: #05141F !important;
border: 0px solid grey !important;

}
input.ant-input {
padding: 0;
color: white !important;
border: none;
background: transparent !important;
outline: none;
}
.ant-input-affix-wrapper {

height: 43px !important;
border: none !important;}
:placeholder {
  color: lightgrey !important;
}
:hover {
  border-color: #05141F !important;

  box-shadow:none !important;
}
:active{
  border-color: #05141F !important;
 
   box-shadow:none !important;
 
}
:focus{
  border-color: #05141F !important;
  
   box-shadow:none !important;
 
}
}
`;
const StyledSelectTestDrive = styled(Select)`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid #e9e9e9; */
    height: 45px !important;
    padding: 5px 11px !important;
    border: 1px solid rgb(6, 20, 31) !important;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-multiple .ant-select-selector {
    height: 45px !important;
  }
  border: 1px solid rgb(6, 20, 31) !important;
  border-radius: 0em !important;
  height: 45px !important;
  box-shadow: none !important;
  :hover {
    border-color: rgb(6, 20, 31) !important;

    box-shadow: none !important;
  }
  :active {
    border-color: rgb(6, 20, 31) !important;

    box-shadow: none !important;
  }
  :focus {
    border-color: rgb(6, 20, 31) !important;

    box-shadow: none !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: rgb(6, 20, 31) !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
  }
`;
const StyledInput = styled(Input)`

  //padding-left: 1.2em !important;
  height: 45px !important;

    box-shadow:none !important;
  background-color: white!important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid black !important;
  border-radius: 0px;
  color: black!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  .ant-input-group-addon .ant-select {
    margin: 0em !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 20px !important;
  color: white !important;
}
.ant-select-arrow {

  color: white !important;

}
.ant-input-group-addon {
  max-height: 3em !important;
  padding: 0px !important;
  color: white !important;
  background-color: #05141F !important;
  border: 0px solid grey !important;
 
}
.ant-input-affix-wrapper {

  height: 43px !important;
  border: none !important;}
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: #05141F !important;

    box-shadow:none !important;
}
  :active{
    border-color: #05141F !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: #05141F !important;
    
     box-shadow:none !important;
   
  }
  }
`;

const StyledTextArea = styled(TextArea)`
padding-left:0.4em !important;
  box-shadow:none !important;
background-color: white!important;
background-image: none;
border: none;
border: 0px solid black !important;
border: 1px solid black !important;
border-radius: 0px;
color: black!important;
//box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
.ant-input:focus, .ant-input-focused {
  border-color:  transparent !important;
  -webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  box-shadow: none !important;
  border-right-width: 1px;
  outline: 0;
}
.ant-input {
border-color: transparent !important;
-webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
box-shadow: none !important;
border-right-width: 1px;
outline: 0;
}
:placeholder {
  color: lightgrey !important;
}
:hover {
  border-color: red !important;

  box-shadow:none !important;
}
:active{
  border-color: red !important;
 
   box-shadow:none !important;
 
}
:focus{
  border-color: red !important;
  
   box-shadow:none !important;
 
}
}
`;
const StyledSelect = styled(Select)`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid #e9e9e9; */
    height: 45px !important;
    padding: 5px 11px !important;
    border: 1px solid black !important;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-multiple .ant-select-selector {
    height: 45px !important;
  }
  border: 1px solid black !important;
  border-radius: 0em !important;
  height: 45px !important;
  box-shadow: none !important;
  :hover {
    border-color: black !important;

    box-shadow: none !important;
  }
  :active {
    border-color: black !important;

    box-shadow: none !important;
  }
  :focus {
    border-color: black !important;

    box-shadow: none !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: black !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
  }
`;
class Steed5SingleCab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      astroGreyVisible: true,
      aliceBlueVisible: false,
      shinyRedVisible: false,
      clearWhiteVisible: false,
      sparklingSilverVisible: false,
      milkyBeigeVisible: false,
      auroraBlackVisible: false,
      honeyBeeVisible: false,
      limeLightVisible: false,
      popOrangeVisible: false,
    };

    this.onFinish = this.onFinish.bind(this);
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
  }
  astroGreyClick = () => {
    this.setState({
      astroGreyVisible: true,
      aliceBlueVisible: false,
      shinyRedVisible: false,
      clearWhiteVisible: false,
      sparklingSilverVisible: false,
      milkyBeigeVisible: false,
      auroraBlackVisible: false,
      honeyBeeVisible: false,
      limeLightVisible: false,
      popOrangeVisible: false,
    });
  };
  aliceBlueClick = () => {
    this.setState({
      astroGreyVisible: false,
      aliceBlueVisible: true,
      shinyRedVisible: false,
      clearWhiteVisible: false,
      sparklingSilverVisible: false,
      milkyBeigeVisible: false,
      auroraBlackVisible: false,
      honeyBeeVisible: false,
      limeLightVisible: false,
      popOrangeVisible: false,
    });
  };
  shinyRedClick = () => {
    this.setState({
      astroGreyVisible: false,
      aliceBlueVisible: false,
      shinyRedVisible: true,
      clearWhiteVisible: false,
      sparklingSilverVisible: false,
      milkyBeigeVisible: false,
      auroraBlackVisible: false,
      honeyBeeVisible: false,
      limeLightVisible: false,
      popOrangeVisible: false,
    });
  };
  clearWhiteClick = () => {
    this.setState({
      astroGreyVisible: false,
      aliceBlueVisible: false,
      shinyRedVisible: false,
      clearWhiteVisible: true,
      sparklingSilverVisible: false,
      milkyBeigeVisible: false,
      auroraBlackVisible: false,
      honeyBeeVisible: false,
      limeLightVisible: false,
      popOrangeVisible: false,
    });
  };
  sparklingSilverClick = () => {
    this.setState({
      astroGreyVisible: false,
      aliceBlueVisible: false,
      shinyRedVisible: false,
      clearWhiteVisible: false,
      sparklingSilverVisible: true,
      milkyBeigeVisible: false,
      auroraBlackVisible: false,
      honeyBeeVisible: false,
      limeLightVisible: false,
      popOrangeVisible: false,
    });
  };
  milkyBeigeClick = () => {
    this.setState({
      astroGreyVisible: false,
      aliceBlueVisible: false,
      shinyRedVisible: false,
      clearWhiteVisible: false,
      sparklingSilverVisible: false,
      milkyBeigeVisible: true,
      auroraBlackVisible: false,
      honeyBeeVisible: false,
      limeLightVisible: false,
      popOrangeVisible: false,
    });
  };
  auroraBlackClick = () => {
    this.setState({
      astroGreyVisible: false,
      aliceBlueVisible: false,
      shinyRedVisible: false,
      clearWhiteVisible: false,
      sparklingSilverVisible: false,
      milkyBeigeVisible: false,
      auroraBlackVisible: true,
      honeyBeeVisible: false,
      limeLightVisible: false,
      popOrangeVisible: false,
    });
  };
  honeyBeeClick = () => {
    this.setState({
      astroGreyVisible: false,
      aliceBlueVisible: false,
      shinyRedVisible: false,
      clearWhiteVisible: false,
      sparklingSilverVisible: false,
      milkyBeigeVisible: false,
      auroraBlackVisible: false,
      honeyBeeVisible: true,
      limeLightVisible: false,
      popOrangeVisible: false,
    });
  };
  limeLightClick = () => {
    this.setState({
      astroGreyVisible: false,
      aliceBlueVisible: false,
      shinyRedVisible: false,
      clearWhiteVisible: false,
      sparklingSilverVisible: false,
      milkyBeigeVisible: false,
      auroraBlackVisible: false,
      honeyBeeVisible: false,
      limeLightVisible: true,
      popOrangeVisible: false,
    });
  };
  popOrangeClick = () => {
    this.setState({
      astroGreyVisible: false,
      aliceBlueVisible: false,
      shinyRedVisible: false,
      clearWhiteVisible: false,
      sparklingSilverVisible: false,
      milkyBeigeVisible: false,
      auroraBlackVisible: false,
      honeyBeeVisible: false,
      limeLightVisible: false,
      popOrangeVisible: true,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };
  showTestDrive = () => {
    this.setState({
      visibleTestDrive: true,
    });
  };
  handleOkTestDrive = (e) => {
    console.log(e);
    this.setState({
      visibleTestDrive: false,
    });
  };

  handleCancelTestDrive = (e) => {
    console.log(e);
    this.setState({
      visibleTestDrive: false,
    });
  };
  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };
  handleSubmit = (values) => {
    this.onFinish(values);
  };
  onFinish = (values) => {
    const MSG = {
      to: [
        "leads@megatheron.co.za",
        //"leads@megatheron.co.za",
        //"allan@megatheron.co.za",
        //"xander@megatheron.co.za",
      ],
      from: "info@mtgroup.co.za",
      subject: "New Test Drive Request",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#101010">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/z5SfxC9/mtgroup.png" width="300" height="85" style="display: block; border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Test Drive Enquiry</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new Test Drive Enquiry from <br /> GWM website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.firstName} ${values.surname}<br />
                          <strong> Number </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.number}<br />
                          <strong> Email </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.email}<br />
                          <strong> Make </strong> &nbsp;&nbsp;:&nbsp;&nbsp; Haval H6 <br />
                          <strong> Dealership </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.dealership}<br />
                 
                      
                       
                             <br /><br />
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2022@Mega Theron Group</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        visible: true,
        visibleTestDrive: false,
        submitDisabled: true,
        title: "",
        firstName: "",
        surname: "",
        number: "",
        email: "",
        make: "",
        dealership: "",
      });
    });
  };

  render() {
    return (
      <div
        className="wrap-body-inner"
        style={{
          margin: "auto",
          marginTop: window.isPhone ? "16em" : "10em",
          maxWidth: "100em",
          backgroundColor: "transparent",
        }}
      >
        <div
          className="banner-item banner-2x banner-bg-11 m-b-lg-0"
          style={{
            height: "40em",
            backgroundColor: "transparent",
          }}
        >
          <img
            //className="banner-img-home"
            style={{
              width: "100vw",
              position: "absolute",
              height: window.isPhone ? "25em" : "50em",
              objectFit: "cover",
              top: window.isPhone ? -145 : -145,
              right: 0,
              zIndex: -1,
            }}
            src={
              window.isPhone
                ? `/images/h6-background-image.webp`
                : `/images/h6-background-image.webp`
            }
            alt="background-image"
          />
          <p
            style={{
              textAlign: "left",
              padding: 0,
              color: "white",
              marginTop: window.isPhone ? "-2em" : "0em",
              textTransform: "none",
              fontWeight: 300,
              fontSize: window.isPhone ? "23px" : "45px",
            }}
          >
            <strong style={{ color: "white" }}>HAVAL H6</strong>
          </p>
          <Link
            to={"/contactUs"}
            className="ht-btn ht-btn-default  p-l-lg-30 p-r-lg-30"
            style={{
              textTransform: "none",
              backgroundColor: "#e40401",
              border: "none",
              marginTop: window.isPhone ? "30px" : "30px",
              float: "left",
              fontSize: window.isPhone ? "10px" : "",
            }}
          >
            CONTACT US
          </Link>
          {window.isPhone ? <br /> : " "}
          <a
            href="/pdfs/haval_H6_brochure.pdf"
            target="_blank"
            className="ht-btn ht-btn-default p-l-lg-30 p-r-lg-30"
            style={{
              textTransform: "none",
              marginLeft: window.isPhone ? "0.5em" : "1em",
              textTransform: "none",
              backgroundColor: "#e40401",
              border: "none",
              marginTop: window.isPhone ? "1em" : "30px",
              float: "left",
              fontSize: window.isPhone ? "10px" : "",
            }}
          >
            BROCHURE
          </a>
          <div
            style={{
              width: "100vw",
              position: "absolute",
              height: window.isPhone ? "27em" : "50em",
              objectFit: "cover",
              top: window.isPhone ? -145 : -145,
              right: 0,
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              zIndex: -1,
            }}
          ></div>
          <Row
            id="test-drive"
            style={{
              maxWidth: "45em",
              margin: "auto",
              marginTop: window.isPhone ? "13em" : "-6em",
              backgroundColor: "black",
              float: window.isPhone ? "center" : "right",
              padding: "2em",
              borderRadius: "1em",
              boxShadow: window.isPhone
                ? "none"
                : "0 10px 30px rgba(0, 0, 0, .8)",
            }}
          >
            <h1
              style={{
                marginTop: window.isPhone ? "1em" : "1em",
                textAlign: window.isPhone ? "center" : "left",
                fontSize: window.isPhone ? "25px" : "30px",
                lineHeight: "0.2em",
                color: "black",
                marginBottom: "1em",
                width: window.isPhone ? "100vw" : "",
                float: "left",
              }}
            >
              BOOK A TEST DRIVE
            </h1>

            <Form
              onFinish={this.handleSubmit}
              initialValues={{
                remember: true,
              }}
              name="wrap"
              labelCol={{ flex: "110px" }}
              labelAlign="left"
              labelWrap
              wrapperCol={{ flex: 1 }}
              colon={false}
              style={{ width: window.isPhone ? "100vw" : "" }}
            >
              <div className="col-sm-12 col-md-6 col-lg-6">
                <Form.Item
                  name="firstName"
                  rules={[{ required: true, message: false }]}
                  hasFeedback
                  style={{
                    borderBottom: "1px solid white",
                  }}
                >
                  <StyledInputTestDrive placeholder="Name" />
                </Form.Item>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <Form.Item
                  name="surname"
                  rules={[{ required: true, message: false }]}
                  hasFeedback
                  style={{
                    borderBottom: "1px solid white",
                  }}
                >
                  <StyledInputTestDrive placeholder="Surname" />
                </Form.Item>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <Form.Item
                  name="number"
                  rules={[{ required: true, message: false }]}
                  hasFeedback
                  style={{
                    borderBottom: "1px solid white",
                  }}
                >
                  <StyledInputTestDrive placeholder="Number" />
                </Form.Item>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <Form.Item
                  name="email"
                  rules={[{ required: false, message: false }]}
                  hasFeedback
                  style={{
                    borderBottom: "1px solid white",
                  }}
                >
                  <StyledInputTestDrive placeholder="Email" />
                </Form.Item>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <Form.Item
                  name="make"
                  rules={[{ required: false, message: false }]}
                  hasFeedback
                  style={{
                    borderBottom: "1px solid white",
                  }}
                >
                  <StyledSelectTestDrive
                    defaultValue="Haval H6"
                    bordered={false}
                    style={{
                      borderRadius: "0em",
                      height: 50,
                      color: "#06141f",
                      textAlign: "left",
                    }}
                    placeholder="Vehicle Model"
                    //onChange={this.setCarEngineCapacity}
                  >
                    <Option value="Haval H6">Haval H6</Option>
                  </StyledSelectTestDrive>
                </Form.Item>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <Form.Item
                  name="dealership"
                  rules={[{ required: false, message: false }]}
                  hasFeedback
                  style={{
                    borderBottom: "1px solid white",
                  }}
                >
                  <StyledSelectTestDrive
                    bordered={false}
                    style={{
                      borderRadius: "0em",
                      height: 50,
                      color: "#06141f",
                      textAlign: "left",
                    }}
                    placeholder="Dealership"
                    //onChange={this.setCarEngineCapacity}
                  >
                    <Option key={1} value="Haval Klerksdorp">
                      Haval Klerksdorp
                    </Option>
                    <Option key={2} value="Haval Potchefstroom">
                      Haval Potchefstroom
                    </Option>
                    <Option key={3} value="Haval Mahikeng">
                      Haval Mahikeng
                    </Option>
                    <Option key={4} value="Haval Lichtenburg">
                      Haval Lichtenburg
                    </Option>
                  </StyledSelectTestDrive>
                </Form.Item>
              </div>

              <div className="col-lg-12 m-t-lg-12 text-center">
                <Form.Item>
                  <Button
                    style={{
                      fontFamily: "Arial",
                      border: "1px solid #E40401",
                      fontSize: "15px",
                      fontWeight: 600,
                      borderRadius: "4em",
                      backgroundColor: "#E40401",
                      color: "white",
                      height: "55px",
                      paddingLeft: "3em",
                      paddingRight: "3em",
                      textShadow: "none",
                      margin: "auto",
                      marginTop: "1em",
                      float: window.isPhone ? "" : "right",
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    CONTACT ME
                  </Button>{" "}
                </Form.Item>{" "}
              </div>
            </Form>
          </Row>
        </div>
        <div
          className=" banner-2x banner-bg-11 color-inher m-b-lg-0"
          style={{
            height: "40em",
            backgroundColor: "transparent",
            marginTop: window.isPhone ? "-9em" : "",
          }}
        >
          <img
            style={{
              width: "100vw",
              position: "absolute",
              height: window.isPhone ? "27em" : "40em",
              objectFit: "cover",
              top: window.isPhone ? 888 : 605,
              right: 0,
              zIndex: -1,
            }}
            src={`${process.env.PUBLIC_URL}/images/h6-highlights-image.webp`}
            alt="logo"
          />

          <p
            style={{
              zIndex: 1,
              maxWidth: "100vw",
              textAlign: "right",
              marginBottom: "0em",
              padding: 0,
              color: "white",
              marginTop: window.isPhone ? "9.5em" : "6em",
              textTransform: "none",
              fontWeight: 300,
              fontSize: window.isPhone ? "25px" : "40px",
            }}
          >
            <strong style={{ lineHeight: "0.5em", color: "white" }}>
              Highlights{" "}
            </strong>
          </p>
          <h6
            style={{
              textAlign: "right",
              padding: 0,
              color: "white",
              fontSize: "15px",
              textTransform: "none",
              fontWeight: 200,
              lineHeight: "1.5em",
            }}
          >
            More advanced and cool technology experience.
          </h6>
          <a
            href="/pdfs/haval_H6_brochure.pdf"
            target="_blank"
            style={{
              color: "white",
              float: "right",
              fontSize: "20px",
            }}
          >
            Explore more
            <i
              class="fa fa-arrow-circle-right"
              aria-hidden="true"
              style={{ color: "rgb(228, 4, 1)", marginLeft: "0.5em" }}
            ></i>
          </a>
          <div
            style={{
              width: "100vw",
              position: "absolute",
              height: window.isPhone ? "27em" : "40em",
              objectFit: "cover",
              top: window.isPhone ? 888 : 605,
              right: 0,
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              zIndex: -1,
            }}
          ></div>
        </div>
        <div
          className=" banner-2x banner-bg-11 color-inher m-b-lg-0"
          style={{ height: "40em", backgroundColor: "transparent" }}
        >
          <img
            style={{
              width: "100vw",
              position: "absolute",
              height: window.isPhone ? "27em" : "40em",
              objectFit: "cover",
              top: window.isPhone ? 1265 : 1215,
              right: 0,
              zIndex: -1,
            }}
            src={`${process.env.PUBLIC_URL}/images/h6-exterior-image.webp`}
            alt="logo"
          />

          <p
            style={{
              zIndex: 1,
              textAlign: "left",
              marginBottom: "0em",
              padding: 0,
              color: "white",
              marginTop: window.isPhone ? "0.8em" : "7.2em",
              textTransform: "none",
              fontWeight: 300,
              fontSize: window.isPhone ? "25px" : "40px",
            }}
          >
            <strong style={{ lineHeight: "1em", color: "white" }}>
              Exterior
            </strong>
          </p>
          {isPhone ? (
            <>
              <h6
                style={{
                  textAlign: "left",
                  padding: 0,
                  color: "white",
                  fontSize: "15px",
                  textTransform: "none",
                  fontWeight: 200,
                  lineHeight: "1.5em",
                }}
              >
                Inspired by a fusion design of Asian and European influences,
                every design detail has been fine-tuned and crafted with a
                purpose and sense of sophisticated elegance.
              </h6>
            </>
          ) : (
            <>
              <h6
                style={{
                  textAlign: "left",
                  padding: 0,
                  color: "white",
                  fontSize: "15px",
                  textTransform: "none",
                  fontWeight: 200,
                  lineHeight: "1.5em",
                }}
              >
                Inspired by a fusion design of Asian and European influences,
                every design detail <br /> has been fine-tuned and crafted with
                a purpose and sense of sophisticated <br /> elegance.
              </h6>
            </>
          )}

          <a
            href="/pdfs/haval_H6_brochure.pdf"
            target="_blank"
            style={{
              color: "white",
              float: "left",
              fontSize: "20px",
            }}
          >
            Explore more
            <i
              class="fa fa-arrow-circle-right"
              aria-hidden="true"
              style={{ color: "rgb(228, 4, 1)", marginLeft: "0.5em" }}
            ></i>
          </a>
          <div
            style={{
              width: "100vw",
              position: "absolute",
              height: window.isPhone ? "27em" : "40em",
              objectFit: "cover",
              top: window.isPhone ? 1265 : 1215,
              right: 0,
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              zIndex: -1,
            }}
          ></div>
        </div>
        <div
          className=" banner-2x banner-bg-11 color-inher m-b-lg-0"
          style={{
            height: window.isPhone ? "" : "40em",
            backgroundColor: "transparent",
          }}
        >
          <img
            style={{
              width: "100vw",
              position: "absolute",
              height: window.isPhone ? "27em" : "40em",
              objectFit: "cover",
              top: window.isPhone ? 1643 : 1826,
              right: 0,
              zIndex: -1,
            }}
            src={`${process.env.PUBLIC_URL}/images/h6-interior-image.webp`}
            alt="logo"
          />

          <p
            style={{
              zIndex: 1,
              maxWidth: "100vw",
              textAlign: "right",
              marginBottom: "0em",
              padding: 0,
              color: "white",
              marginTop: window.isPhone ? "-6.5em" : "8.5em",
              textTransform: "none",
              fontWeight: 300,
              fontSize: window.isPhone ? "25px" : "40px",
            }}
          >
            <strong style={{ lineHeight: "0.5em", color: "white" }}>
              Interior{" "}
            </strong>
          </p>
          {isPhone ? (
            <>
              <h6
                style={{
                  textAlign: "right",
                  padding: 0,
                  color: "white",
                  fontSize: "15px",
                  textTransform: "none",
                  fontWeight: 200,
                  lineHeight: "1.5em",
                }}
              >
                From the power-adjustable, heated front seats beneath the
                spectacular panoramic sunroof, its impossible to deny that the
                HAVAL H6 offers unparalleled comfort to both drivers and
                passengers.
              </h6>
            </>
          ) : (
            <>
              <h6
                style={{
                  textAlign: "right",
                  padding: 0,
                  color: "white",
                  fontSize: "15px",
                  textTransform: "none",
                  fontWeight: 200,
                  lineHeight: "1.5em",
                }}
              >
                From the power-adjustable, heated front seats beneath the
                spectacular panoramic <br />
                sunroof, its impossible to deny that the HAVAL H6 offers
                unparalleled comfort to <br /> both drivers and passengers.
              </h6>
            </>
          )}

          <a
            href="/pdfs/haval_H6_brochure.pdf"
            target="_blank"
            style={{
              color: "white",
              float: "right",
              fontSize: "20px",
            }}
          >
            Explore more
            <i
              class="fa fa-arrow-circle-right"
              aria-hidden="true"
              style={{ color: "rgb(228, 4, 1)", marginLeft: "0.5em" }}
            ></i>
          </a>
          <div
            style={{
              width: "100vw",
              position: "absolute",
              height: window.isPhone ? "27em" : "40em",
              objectFit: "cover",
              top: window.isPhone ? 1643 : 1826,
              right: 0,
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              zIndex: -1,
            }}
          ></div>
        </div>
        <div
          className=" banner-2x banner-bg-11 color-inher m-b-lg-0"
          style={{ height: "40em", backgroundColor: "transparent" }}
        >
          <img
            style={{
              width: "100vw",
              position: "absolute",
              height: window.isPhone ? "27em" : "40em",
              objectFit: "cover",
              top: window.isPhone ? 2022 : 2437,
              right: 0,
              zIndex: -1,
            }}
            src={`${process.env.PUBLIC_URL}/images/h6-safety-image.webp`}
            alt="logo"
          />

          <p
            style={{
              zIndex: 1,
              textAlign: "left",
              marginBottom: "0em",
              padding: 0,
              color: "white",
              marginTop: window.isPhone ? "6em" : "9.8em",
              textTransform: "none",
              fontWeight: 300,
              fontSize: window.isPhone ? "25px" : "40px",
            }}
          >
            <strong style={{ lineHeight: "1em", color: "white" }}>
              Safety
            </strong>
          </p>
          {isPhone ? (
            <>
              <h6
                style={{
                  textAlign: "left",
                  padding: 0,
                  color: "white",
                  fontSize: "15px",
                  textTransform: "none",
                  fontWeight: 200,
                  lineHeight: "1.5em",
                }}
              >
                The HAVAL H6 boasts an intelligent suite of safety features, so
                you can spend More time enjoying the journey. Cruise with
                confidence in every direction with Autonomous Emergency Braking,
                Adaptive Cruise Control, Lane-Keep Assist and Rear Cross-Traffic
                Alert.
              </h6>
            </>
          ) : (
            <>
              <h6
                style={{
                  textAlign: "left",
                  padding: 0,
                  color: "white",
                  fontSize: "15px",
                  textTransform: "none",
                  fontWeight: 200,
                  lineHeight: "1.5em",
                }}
              >
                The HAVAL H6 boasts an intelligent suite of safety features, so
                you can spend more <br /> time enjoying the journey. Cruise with
                confidence in every direction with <br /> Autonomous Emergency
                Braking, Adaptive Cruise Control, <br /> Lane-Keep Assist and
                Rear Cross-Traffic Alert.
              </h6>
            </>
          )}

          <a
            href="/pdfs/haval_H6_brochure.pdf"
            target="_blank"
            style={{
              color: "white",
              float: "left",
              fontSize: "20px",
            }}
          >
            Explore more
            <i
              class="fa fa-arrow-circle-right"
              aria-hidden="true"
              style={{ color: "rgb(228, 4, 1)", marginLeft: "0.5em" }}
            ></i>
          </a>
          <div
            style={{
              width: "100vw",
              position: "absolute",
              height: window.isPhone ? "27em" : "40em",
              objectFit: "cover",
              top: window.isPhone ? 2022 : 2437,
              right: 0,
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              zIndex: -1,
            }}
          ></div>
        </div>
        {/* <div
          className=" banner-2x banner-bg-11 color-inher m-b-lg-0"
          style={{ height: "40em", backgroundColor: "transparent" }}
        >
          <img
            style={{
              width: "100vw",
              position: "absolute",
              height: window.isPhone ? "27em" : "40em",
              objectFit: "cover",
              top: window.isPhone ? 2400 : 3047,
              right: 0,
              zIndex: -1,
            }}
            src={`${process.env.PUBLIC_URL}/images/h6-finance-image.webp`}
            alt="logo"
          />

          <p
            style={{
              zIndex: 1,
              maxWidth: "100vw",
              textAlign: "right",
              marginBottom: "0em",
              padding: 0,
              color: "white",
              marginTop: window.isPhone ? "0.8em" : "11em",
              textTransform: "none",
              fontWeight: 300,
              fontSize: window.isPhone ? "25px" : "40px",
            }}
          >
            <strong style={{ lineHeight: "0.5em", color: "white" }}>
              Finance{" "}
            </strong>
          </p>
          <h6
            style={{
              textAlign: "right",
              padding: 0,
              color: "white",
              fontSize: "15px",
              textTransform: "none",
              fontWeight: 200,
              lineHeight: "1.5em",
            }}
          >
            Apply for financing today!
          </h6>
          <a
            href="/pdfs/haval_H6_brochure.pdf"
            target="_blank"
            style={{
              color: "white",
              float: "right",
              fontSize: "20px",
            }}
          >
            Explore more
            <i
              class="fa fa-arrow-circle-right"
              aria-hidden="true"
              style={{ color: "rgb(228, 4, 1)", marginLeft: "0.5em" }}
            ></i>
          </a>
          <div
            style={{
              width: "100vw",
              position: "absolute",
              height: window.isPhone ? "27em" : "40em",
              objectFit: "cover",
              top: window.isPhone ? 2400 : 3047,
              right: 0,
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              zIndex: -1,
            }}
          ></div>
        </div> */}

        <Row
          id="test-drive"
          style={{
            maxWidth: window.isPhone ? "45em" : "60em",
            margin: "auto",
            marginTop: window.isPhone ? "-8em" : "4em",
            padding: window.isPhone ? "3em" : "",
          }}
        >
          <h1
            style={{
              marginTop: window.isPhone ? "1em" : "7.9em",
              textAlign: window.isPhone ? "center" : "left",
              fontSize: window.isPhone ? "25px" : "30px",
              lineHeight: "0.2em",
              color: "black",
              marginBottom: "1em",
              width: window.isPhone ? "100vw" : "",
              float: "left",
            }}
          >
            BOOK A TEST DRIVE
          </h1>

          <Form
            onFinish={this.handleSubmit}
            initialValues={{
              remember: true,
            }}
            name="wrap"
            labelCol={{ flex: "110px" }}
            labelAlign="left"
            labelWrap
            wrapperCol={{ flex: 1 }}
            colon={false}
            style={{ width: window.isPhone ? "100vw" : "" }}
          >
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Form.Item
                name="firstName"
                rules={[{ required: true, message: false }]}
                hasFeedback
                style={{
                  borderBottom: "1px solid white",
                }}
              >
                <StyledInputTestDrive placeholder="Name" />
              </Form.Item>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Form.Item
                name="surname"
                rules={[{ required: true, message: false }]}
                hasFeedback
                style={{
                  borderBottom: "1px solid white",
                }}
              >
                <StyledInputTestDrive placeholder="Surname" />
              </Form.Item>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Form.Item
                name="number"
                rules={[{ required: true, message: false }]}
                hasFeedback
                style={{
                  borderBottom: "1px solid white",
                }}
              >
                <StyledInputTestDrive placeholder="Number" />
              </Form.Item>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Form.Item
                name="email"
                rules={[{ required: false, message: false }]}
                hasFeedback
                style={{
                  borderBottom: "1px solid white",
                }}
              >
                <StyledInputTestDrive placeholder="Email" />
              </Form.Item>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Form.Item
                name="make"
                rules={[{ required: false, message: false }]}
                hasFeedback
                style={{
                  borderBottom: "1px solid white",
                }}
              >
                <StyledSelectTestDrive
                  defaultValue="Haval H6"
                  bordered={false}
                  style={{
                    borderRadius: "0em",
                    height: 50,
                    color: "#06141f",
                  }}
                  placeholder="Vehicle Model"
                  //onChange={this.setCarEngineCapacity}
                >
                  <Option value="Haval H6">Haval H6</Option>
                </StyledSelectTestDrive>
              </Form.Item>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <Form.Item
                name="dealership"
                rules={[{ required: false, message: false }]}
                hasFeedback
                style={{
                  borderBottom: "1px solid white",
                }}
              >
                <StyledSelectTestDrive
                  bordered={false}
                  style={{
                    borderRadius: "0em",
                    height: 50,
                    color: "#06141f",
                  }}
                  placeholder="Dealership"
                  //onChange={this.setCarEngineCapacity}
                >
                  <Option key={1} value="GWM Klerksdorp">
                    GWM Klerksdorp
                  </Option>
                  <Option key={2} value="GWM Potchefstroom">
                    GWM Potchefstroom
                  </Option>
                  <Option key={3} value="GWM Mahikeng">
                    GWM Mahikeng
                  </Option>
                  <Option key={4} value="GWM Lichtenburg">
                    GWM Lichtenburg
                  </Option>
                </StyledSelectTestDrive>
              </Form.Item>
            </div>

            <div className="col-lg-12 m-t-lg-12 text-center">
              <Form.Item>
                <Button
                  style={{
                    fontFamily: "Arial",
                    border: "1px solid #E40401",
                    fontSize: "15px",
                    fontWeight: 600,
                    borderRadius: "4em",
                    backgroundColor: "#E40401",
                    color: "white",
                    height: "55px",
                    paddingLeft: "3em",
                    paddingRight: "3em",
                    textShadow: "none",
                    margin: "auto",
                    marginTop: "1em",
                    float: window.isPhone ? "" : "right",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  CONTACT ME
                </Button>{" "}
              </Form.Item>{" "}
            </div>
          </Form>
        </Row>
        <Modal
          visible={this.state.visible}
          style={{ textAlign: "center" }}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            alt="Logo"
            style={{ width: "20em", margin: "auto", marginTop: "2em" }}
          />
          <h3
            style={{
              marginTop: "2em",
              color: "black",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Thank you for contacting
            <br />
            <span style={{ color: "rgb(6, 20, 31)" }}>GWM.</span> <br />
            <br />
            <span style={{ color: "black ", fontSize: "17px" }}>
              Our vehicle specialist will be in touch shortly.
            </span>{" "}
            <br />
          </h3>{" "}
          <br />
        </Modal>
      </div>
    );
  }
}
export default Steed5SingleCab;
