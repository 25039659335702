import React, { Component } from "react";
import { Row, Col, Divider, Button, Badge } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Fade from "react-reveal/Fade";
import {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
} from "../../redux/actions";
import CurrencyFormat from "react-currency-format";

if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
class StockItemCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInWishList: false,
      stock: props.stock,
      isHovered: false,
    };
  }
  ToggleCarWishlist(event) {
    var stock = JSON.parse(event.target.id);
    if (!this.state.isInWishList) {
      this.props.addToWishList(stock);
      this.setState({ isInWishList: true });
    } else {
      this.props.removeFromWishList(stock);
      this.setState({ isInWishList: false });
    }
  }
  componentDidMount() {
    var stock = this.props.stock;

    if (
      this.props.wishlistproducts.filter(function (e) {
        return e._id === stock._id;
      }).length === 1
    ) {
      this.setState({ isInWishList: true });
    }
  }
  mouseHover(event) {
    this.setState({ isHovered: true });
  }

  mouseUnHover(event) {
    this.setState({ isHovered: false });
  }

  render() {
    //var heartStyle = {};
    //if (!this.state.isHovered) {
    //  heartStyle = {
    //    color: "white",
    //    fontWeight: "bold",
    //  };
    //}

    const { stock } = this.props;
    return (
      //<Fade>
      stock.webVisible && stock.megaDealers === "HAVAL" ? (

        <div className='col-sm-12 col-md-4 col-lg-4' style={{ padding: "1em", float: "auto", width: window.isPhone ? "" : "30%", margin: "1.5%" }}>
          <Badge.Ribbon color="#CD2934" text={stock.condition.toUpperCase()} style={{ display: stock.condition ? "" : "none" }}>
            <div className='product-item' style={{ margin: "auto", marginBottom: "1em" }}>
              {stock.onSale === true ? (
                <span
                  style={{
                    background: "rgb(205, 41, 52)",
                    borderRadius: "5em",
                    padding: "0.7em",
                    paddingTop: "1.1em",
                    paddingBottom: "1em",
                    position: "absolute",
                    left: 10,
                    top: 0,
                    color: "white",
                    fontSize: 11,
                  }}
                >
                  SALE
                </span>
              ) : null}
              {/* {stock.condition ? (
                <span
                  style={{
                    background:
                      stock.condition === "New"
                        ? "rgb(5, 20, 31)"
                        : stock.condition === "Demo"
                          ? "rgb(5, 20, 31)"
                          : "rgb(5, 20, 31)",
                    borderRadius: "0em",
                    padding: "0.3em",
                    paddingRight: "0.8em",
                    paddingLeft: "0.8em",
                    position: "absolute",
                    // right: 10,
                    // top: 158,
                    width: "4.3em",
                    color: "white",
                  }}
                >
                  {stock.condition.toUpperCase()}
                </span>
              ) : null} */}
              {/* {stock.modelYear ? (
              <span
                style={{
                  background: "rgba(0, 0, 0, 0.529)",
                  borderRadius: "0em",
                  padding: "0.3em",
                  paddingRight: "0.8em",
                  paddingLeft: "0.8em",
                  position: "absolute",
                  right: stock.condition ? 75 : 10,
                  top: 158,
                  color: "white",
                }}
              >
                {stock.modelYear}
              </span>
            ) : null} */}
              <Link
                className='product-img'
                onClick={() => this.props.fetchSingleStock(stock)}
                to={`/stock/vehicle-details`}
              >
                {stock.frontLeftImage ? (
                  <img
                    src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${stock.frontLeftImage}`}
                    alt=''
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/images/coming-soon.png`}
                    alt=''
                  />
                )}
              </Link>
              <div className='product-caption'>
                <h4
                  className='product-name'
                  style={{ marginBottom: "0em", marginTop: "0em", paddingLeft: 0, textTransform: "uppercase", borderBottom: "1px solid lightgrey" }}
                >
                  <Link
                    onClick={() => this.props.fetchSingleStock(stock)}
                    to={`/stock/vehicle-details`}
                  >
                    <span className='f-20' style={{
                      display: "block", color: "white",

                    }}>
                      <span className='f-20' style={{
                        display: "block", color: "white",
                        whiteSpace: "nowrap",
                        width: "70%",
                        textOverflow: "ellipsis",
                        overflow: "hidden"
                      }}>
                        {stock.make}  {stock.series}</span>
                      <span
                        className='f-20'
                        style={{ fontWeight: 700, float: "right", color: "#CD2934", marginTop: "-1.5em", textAlign: "right" }}
                      >
                        <CurrencyFormat
                          value={stock.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"R"}
                          //format='R### ### ### ###'
                          renderText={(value) => <div>{value}</div>}
                        />
                      </span>
                      <p
                        className='f-14'
                        style={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          paddingRight: "3em",
                          overflow: "hidden",
                          width: "21em",
                          color: "white"
                        }}
                      >
                        {stock.modelDesc}
                      </p>
                    </span>
                  </Link>
                </h4>{" "}
              </div>
            </div>
          </Badge.Ribbon>

          <ul className='static-caption m-t-lg-20' style={{ borderTop: "0px solid lightgrey", margin: "auto", height: window.isPhone ? "8em" : "" }}>
            <li style={{ color: "white" }}>
              <i style={{ color: "white" }} class="fa fa-map-marker" aria-hidden="true"></i>
              {stock.brand} {stock.branch}
            </li>
            <li>
              {/* <i className='fa fa-tachometer'></i>
                  {stock.transmission} */}
            </li>
            <li>
              <Link
                className='product-img'
                onClick={() => this.props.fetchSingleStock(stock)}
                to={`/stock/vehicle-details`}
              >
                <Button
                  style={{
                    fontFamily: "Arial",
                    border: "0px solid white",
                    fontSize: "12px",
                    fontWeight: 600,
                    borderRadius: "3em",
                    backgroundColor: "transparent",
                    color: "white",
                    // paddingLeft: "3em",
                    // paddingRight: "3em",
                    height: "25px",
                    textShadow: "none",
                    margin: "auto",
                    // float: "right"
                  }}
                  type='primary'
                >
                  View Specs  <i style={{ color: "white", marginLeft: "1em" }} class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                </Button>
              </Link>
              {/* <CurrencyFormat
                    value={stock.mileage}
                    displayType={"text"}
                    thousandSeparator={true}
                    sufffix={"km"}
                    //format='R### ### ### ###'
                    renderText={(value) => (
                      <div style={{ display: "inline-block" }}>{value}</div>
                    )}
                  /> */}
            </li>
          </ul>


        </div>
      ) : null
      //</Fade>
    );
  }
}

function mapStateToProps({ wishlistproducts }) {
  return { wishlistproducts };
}

export default connect(mapStateToProps, {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
})(StockItemCard);
