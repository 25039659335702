import React, { Component, useState } from "react";
import { Link } from "react-router-dom";

// import kiaPromo from "./../videos/ca_kia_2243_HP-Sizzle-Q422_C_v02_H264-10MB.mp4";

import {
  Checkbox,
  Select,
  Divider,
  Slider,
  Switch,
  Collapse,
  Button,
  Popover,
  Row,
  Col,
} from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}

class KiaRange extends Component {
  render() {
    return (
      <section className="m-t-lg-100 m-t-xs-0" style={{ margin: "auto" }}>
        <div
          style={{
            background: "white",
            padding: 0,
          }}
        >
          <div
          // style={{ backgroundColor: "rgb(242, 244, 244)" }}
          >
            <Row
              justify="center"
              style={{
                margin: "auto",
              }}
            >
              <Col span={24}>
                <div className="video-overlay-1"></div>

                <img
                  src={`${process.env.PUBLIC_URL}/images/haval-range-banner.webp`}
                  style={{
                    width: "100vw",
                    height: window.isPhone ? "16em" : "20em",
                    objectFit: "cover",
                  }}
                />
              </Col>
              {/* <Col span={12}>
                </Col>
                <img
                  src={`${process.env.PUBLIC_URL}/images/mahikeng-cars-row.png`}
                  alt='logo'
                  style={{ width: "50em", position: "absolute", right: 0, marginTop: "-5em" }}
                /> */}
            </Row>
          </div>
        </div>
        <div
          style={{
            background: "black",
            height: "5em",
            width: "100vw",
            marginTop: window.isPhone ? "4em" : "2em",
            paddingLeft: window.isPhone ? "" : "7em",
          }}
        >
          <h3
            className="f-weight-600 f-30"
            style={{
              textAlign: window.isPhone ? "center" : "left",
              paddingTop: "0.6em",
              color: "white",
              maxWidth: "90em",
              margin: "auto",
            }}
          >
            The Complete Range
          </h3>
        </div>
        <div
          style={{
            margin: "auto",
            marginTop: "0em",
            padding: window.isPhone ? "" : "3em",
            paddingTop: "1em",
          }}
        >
          <div
            className="col-sm-12 col-md-12 col-lg-12"
            style={{ marginBottom: "4em", marginTop: "2em" }}
          >
            <div className="product product-grid product-grid-2 stock">
              <div className="clearfix"></div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="product-item hover-img ">
                  <Link className="product-img" to={`/jolion`}>
                    <img
                      style={{
                        objectFit: "contain",
                        width: "20em",
                        height: "12em",
                        backgroundColor: "#1c1c1c",
                      }}
                      src={`${process.env.PUBLIC_URL}/images/jolion-range-img.webp`}
                      alt=""
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className="product-name"
                      style={{
                        marginBottom: "0em",
                        marginTop: "0em",
                        fontSize: "17px",
                        color: "#8f8f8f",
                      }}
                    >
                      <Link to={`/jolion`}>
                        <span
                          style={{
                            display: "block",
                            textTransform: "uppercase",
                          }}
                        >
                          HAVAL Jolion{" "}
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                            style={{ marginLeft: "0.2em" }}
                          ></i>
                        </span>
                      </Link>
                    </h4>
                    <div className="product-name"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="product-item hover-img ">
                  <Link className="product-img" to={`/jolion-s`}>
                    <img
                      style={{
                        objectFit: "contain",
                        width: "20em",
                        height: "12em",
                        backgroundColor: "#1c1c1c",
                      }}
                      src={`${process.env.PUBLIC_URL}/images/jolion-s-range-img.webp`}
                      alt=""
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className="product-name"
                      style={{
                        marginBottom: "0em",
                        marginTop: "0em",
                        fontSize: "17px",
                        color: "#8f8f8f",
                      }}
                    >
                      <Link to={`/jolion-s`}>
                        <span
                          style={{
                            display: "block",
                            textTransform: "uppercase",
                          }}
                        >
                          HAVAL Jolion S{" "}
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                            style={{ marginLeft: "0.2em" }}
                          ></i>
                        </span>
                      </Link>
                    </h4>
                    <div className="product-name"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="product-item hover-img ">
                  <Link className="product-img" to={`/jolion-hev`}>
                    <img
                      style={{
                        objectFit: "contain",
                        width: "20em",
                        height: "12em",
                        backgroundColor: "#1c1c1c",
                      }}
                      src={`${process.env.PUBLIC_URL}/images/jolion-hev-range-img.webp`}
                      alt=""
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className="product-name"
                      style={{
                        marginBottom: "0em",
                        marginTop: "0em",
                        fontSize: "17px",
                        color: "#8f8f8f",
                      }}
                    >
                      <Link to={`/jolion-hev`}>
                        <span
                          style={{
                            display: "block",
                            textTransform: "uppercase",
                          }}
                        >
                          HAVAL Jolion HEV{" "}
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                            style={{ marginLeft: "0.2em" }}
                          ></i>
                        </span>
                      </Link>
                    </h4>
                    <div className="product-name"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="product-item hover-img ">
                  <Link className="product-img" to={`/h6`}>
                    <img
                      style={{
                        objectFit: "contain",
                        width: "20em",
                        height: "12em",
                        backgroundColor: "#1c1c1c",
                      }}
                      src={`${process.env.PUBLIC_URL}/images/h6-range-img.webp`}
                      alt=""
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className="product-name"
                      style={{
                        marginBottom: "0em",
                        marginTop: "0em",
                        fontSize: "17px",
                        color: "#8f8f8f",
                      }}
                    >
                      <Link to={`/h6`}>
                        <span
                          style={{
                            display: "block",
                            textTransform: "uppercase",
                          }}
                        >
                          HAVAL H6{" "}
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                            style={{ marginLeft: "0.2em" }}
                          ></i>
                        </span>
                      </Link>
                    </h4>
                    <div className="product-name"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="product-item hover-img ">
                  <Link className="product-img" to={`/h6-gt`}>
                    <img
                      style={{
                        objectFit: "contain",
                        width: "20em",
                        height: "12em",
                        backgroundColor: "#1c1c1c",
                      }}
                      src={`${process.env.PUBLIC_URL}/images/h6-gt-range-img.webp`}
                      alt=""
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className="product-name"
                      style={{
                        marginBottom: "0em",
                        marginTop: "0em",
                        fontSize: "17px",
                        color: "#8f8f8f",
                      }}
                    >
                      <Link to={`/h6-gt`}>
                        <span
                          style={{
                            display: "block",
                            textTransform: "uppercase",
                          }}
                        >
                          HAVAL H6 GT{" "}
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                            style={{ marginLeft: "0.2em" }}
                          ></i>
                        </span>
                      </Link>
                    </h4>
                    <div className="product-name"></div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="product-item hover-img ">
                  <Link className="product-img" to={`/h6-hev`}>
                    <img
                      style={{
                        objectFit: "contain",
                        width: "20em",
                        height: "12em",
                        backgroundColor: "#1c1c1c",
                      }}
                      src={`${process.env.PUBLIC_URL}/images/h6-hev-range-img.webp`}
                      alt=""
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className="product-name"
                      style={{
                        marginBottom: "0em",
                        marginTop: "0em",
                        fontSize: "17px",
                        color: "#8f8f8f",
                      }}
                    >
                      <Link to={`/h6-hev`}>
                        <span
                          style={{
                            display: "block",
                            textTransform: "uppercase",
                          }}
                        >
                          HAVAL H6 HEV{" "}
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                            style={{ marginLeft: "0.2em" }}
                          ></i>
                        </span>
                      </Link>
                    </h4>
                    <div className="product-name"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default KiaRange;
